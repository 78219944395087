
import { fetchCart, fetchUser } from "../utils/fetchLocalStorageData";
import {fetchUserFromFirebase} from '../utils/FirebaseFunctions'; 
import {firestore } from "../firebase-config";

const userInfo = fetchUser(); 
const cartInfo = fetchCart();

// console.log("USER INFO : ", userInfo)

// const getInitialUserPoint = () => {
//     if(userInfo) {
//                 fetchUserFromFirebase(firestore, userInfo.uid)
//                    .then((result)=>{
//                        if(result){
//                        console.log("fetchUserFromFirebase : ", result) 
//                        // if(result===providerData[0].uid){
//                        return result.uid
//                    }else{
//                     return null
//                    }                     // }
//                })
//   }
// }


// const puan = getInitialUserPoint();


export const initialState = {
    user: userInfo,
    foodItems: null,
    cartShow : false,
    cartItems : cartInfo,
    puan: null,
    remainingvideopoint: 1267

}