import React, { useEffect, useRef, useState } from 'react';
import { MdShoppingBasket } from 'react-icons/md';
import { motion } from 'framer-motion';
import NotFound from './img/NotFound.svg';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';


const RowContainer = ({flag, data, scrollValue, companyId}) => {

    // console.log("DATAA : ", data);
    // console.log("FLAG : ", flag);
    // console.log("SCROLLVALUE : ", scrollValue);

    const rowContainerRef = useRef();
    const videoRefs = useRef([]);
    const [isVideoEnded, setVideoEnded] = useState(false);
    const [showQuiz, setShowQuiz] = useState(true);

    const isInitialMount = useRef(true);


    //*********RIGHT CLICK */
    // document.addEventListener("contextmenu", function(event){
    // event.preventDefault();
    // }, false);




    const handleVideoClick = (e,index, videoid) => {
        const video = videoRefs.current[index];
        e.preventDefault()
        if (video) {
            if (video.requestFullscreen) {
                // video.requestFullscreen();
                video.id = "myVideo";
                video.className ="absolute z-10 w-auto min-w-full min-h-full max-w-none";

                // console.log("VIDEO : ", video)

            } else if (video.mozRequestFullScreen) { // Firefox
              video.mozRequestFullScreen();

            } else if (video.webkitRequestFullscreen) { // Chrome, Safari, Opera
              video.webkitRequestFullscreen();

            } else if (video.msRequestFullscreen) { // IE/Edge
              video.msRequestFullscreen();}

            video.play();

            // window.open(video.play(), '_blank')
          }
    };


    const handleVideoEnded = () => {
        // console.log("VIDEO BITTI");     
        Quiz();
        // setShowQuiz(true);
        // isInitialMount.current = false;

        // if (document.fullscreenElement) {
        //   if (document.exitFullscreen) {
        //     document.exitFullscreen();
        //   } else if (document.mozCancelFullScreen) {
        //     document.mozCancelFullScreen();
        //   } else if (document.webkitExitFullscreen) {
        //     document.webkitExitFullscreen();
        //   } else if (document.msExitFullscreen) {
        //     document.msExitFullscreen();
        //   }
        // }
      };

      const Quiz = () => {
        // setShowQuiz(true);
        // console.log("QUIZ ÇALIŞTI");
        setShowQuiz(true);
        return <div>Fullscreen Quiz</div>;
      };

    const handleFullscreenChange = (index) => {
        return () => {
          const videoElement = videoRefs.current[index];

          if (!document.fullscreenElement) {
            if (videoElement) {
            //   videoElement.pause();
            //   setShowQuiz(false);
            }
          }
        };
      };

      useEffect(() => {


        if (!isInitialMount.current) {

          data.forEach((_, index) => {
            document.addEventListener('fullscreenchange', handleFullscreenChange(index));
          });
  
          return () => {
              data.forEach((_, index) => {
              document.removeEventListener('fullscreenchange', handleFullscreenChange(index));
            });
          };
        }



      }, [data]);



    //   useEffect(() => {
    //     if (isInitialMount.current) {
    //     //    isInitialMount.current = false;
    //        console.log("isInitialMount.current 1 : ", isInitialMount.current)
    //     } else {
    //         // Your useEffect code here to be run on update
    //         console.log("USEEFFECT SHOWQUIZ STATE : ", showQuiz)
    //         console.log("isInitialMount.current 2 : ", isInitialMount.current)

            
    //         if(showQuiz){
    //         Quiz() }
    //     }
    //   }, [showQuiz]);


      const recommendations = [
        {
          title: 'Video 1',
          channel: 'Channel 1',
          thumbnail: 'thumbnail1.jpg',
        },
        {
          title: 'Video 2',
          channel: 'Channel 2',
          thumbnail: 'thumbnail2.jpg',
        },
        // Add more video recommendations as needed
      ];


// console.log("show Quiz : ", showQuiz);

  return (
    <div ref={rowContainerRef} className={` w-full flex items-center gap-3 my-12 scroll-smooth ${flag ? 'overflow-x-scroll scrollbar-none' : "overflow-x-hidden flex-wrap justify-center"}`}>

        {data && data.length > 0 ? data.map((item, index) =>(
    <>

            <motion.div 
              whileHover={{scale: 1.2 }} className="w-275 h-[175px] min-w-[275px] md:w-300 md:min-w-[300px]  bg-cardOverlay rounded-lg p-2 px-4 my-12 backdrop-blur-lg hover:bg-blue-200 hover:drop-shadow-lg flex flex-col items-cente justify-evenly relative"

             >
                <div  className="w-full flex items-center justify-between video-container">

                    {/* <motion.div className='w-40 h-40 -mt-8 drop-shadow-2xl ' whileHover={{scale: 1.2 }}> */}
                    <div  >
                        <video  src={item?.video_url} alt="" className='w-full h-full video-player'
                        ref={(element) => (videoRefs.current[index]=element)}
                        onClick={(e)=>handleVideoClick(e, index, "myVideo")}
                        onEnded={handleVideoEnded}
                        disablePictureInPicture controlsList="nodownload noplaybackrate"
                        />

                            {/* <h1><Quiz /></h1> */}
                        {showQuiz && (<>
                        {/* {console.log("overlay çıkmalı")} */}
                                      <div id="overlay" >
                                      <div id="text">Overlay Text</div>
                                    </div>
                                    </>
                                // <div className="content">Recommended Videos</div>

                                )}
                                {/* <div className="video-overlay recommendations-list">
                                    {recommendations.map((video, index) => (
                                    <div key={index} className="recommendation">
                                        <img src={video.thumbnail} alt={video.title} />
                                        <div className="recommendation-info">
                                        <h3>{video.title}</h3>
                                        <p>{video.channel}</p>
                                        </div>
                                    </div>
                                    ))}
                                </div> */}


                    </div>



                    {/*
                    <motion.div
                        whileTap={{scale: 0.75}}
                        className="w-8 h-8 rounded-full bg-red-600 flex items-center justify-center cursor-pointer hover:shadow-md"
                        onClick={()=> setItems([...cartItems, item])}
                    >
                        <MdShoppingBasket className='text-white' />
                    </motion.div> */}
                </div>

                <div className="w-full flex flex-col items-end justify-end">
                    {/* <p className='text-textColor font-semibold text-base md:tex-lg'>{item?.title}</p> */}
                    <p className='mt-1 text-sm text-gray-500 '> {companyId} - {item?.video_title}</p>
                    {/* <div className="flex items-center gap-8">
                        <p className='text-lg text-headingColor font-semibold'><span className='text-sm text-red-500'>$</span>{item?.price}</p>
                    </div> */}
                </div>

            </motion.div>
            </>
        )
        ) :
        <div className="w-full flex flex-col items-center justify-center">
            <img src={NotFound} className='h-340'/>
            <p className='text-xl text-headingColor font-semibold my-4 '>Items Not Available</p>
        </div>}

    </div>
  );
}

export default RowContainer;


