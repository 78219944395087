import React, { useEffect } from 'react';

import './App.css';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { getAllFoodItems } from './utils/FirebaseFunctions';
import { actionType } from './context/reducer';

import Anasayfa from './components/Anasayfa';


function App() {

  




  return (

    <AnimatePresence mode='wait'>
      <div className='w-screen h-auto flex flex-col bg-primary' >
          <Header />
          <main className='mt-14 md:mt-20 px-4 md:px-16 py-4 w-full '>
            <Routes>
              {/* <Route path='/*' element={<MainContainer />} /> */}
              <Route path='/*' element={<Anasayfa />} />
              {/* <Route path='/Temp' element={<Temp />} /> */}
              
             
            </Routes>
          </main>
      </div>
    </AnimatePresence> 
  );
}

export default App;
