import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { motion } from 'framer-motion';
import { MdContentPasteSearch } from 'react-icons/md';
import { useStateValue } from '../../context/StateProvider';
import { actionType } from '../../context/reducer';
import NotFound from '../img/NotFound.svg';
import { firestore } from "../../firebase-config";
import { setFireStoretoRemainingVideoPoint, saveUserPoint, getUserEarnedPointsForVideo  } from '../../utils/FirebaseFunctions';
import { connectStorageEmulator } from 'firebase/storage';
// import UserAndVideo from './UserAndVideo ';

const VideoComponent = ({ flag, data, scrollValue, companyId, onRemainingVideoPointUpdate }) => {



  // console.log("DATA : ", data[0].company)
  const [{user, puan, remainingvideopoint }, dispatch] = useStateValue();

  // console.log("remainingvideopoint :", remainingvideopoint)
  // console.log("DATA : ", data)

  // console.log("puan USE state altında : ", puan)
  // console.log("USERRRRR : ", user)

  const [remainingVideoPoints, setRemainingVideoPoints] = useState([]);
  const videoContainerRef = useRef([]);
  const mainVideoRef = useRef([]);
  const currentVidTimeRef = useRef([]);
  const videoDurationRef = useRef([]);
  const [isPlaying, setIsPlaying] = useState(false);
  
  const [isAnswerClicked, setIsAnswerClicked] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [videoStates, setVideoStates] = useState([]);
  const [isPointsExpired, setIsPointsExpired] = useState(false);
  const [expiredVideoIndex, setExpiredVideoIndex] = useState(null);
  const [isFullscreenExited, setIsFullscreenExited] = useState(false);

  const [isVideoExpired, setIsVideoExpired] = useState([]);
  const [showNoVideoPoint, setShowNoVideoPoint] = useState(false);
  
  const [isFullscreen, setIsFullscreen] = useState([]);
  const [userEarnedPoints, setUserEarnedPoints] = useState([]); // Store a list of videos for which the user earned points
  const [showMessage, setShowMessage] = useState(false);
  const [videoIdforearned, setVideoIdforearned] = useState(null);

  const firestoreDb = firestore;

  const [randomQuestion, setRandomQuestion] = useState([]);


  console.log("1111- randomQuestion :", randomQuestion)


  //PLAY PAUSE VIDEO
  const playPauseVideo = (e, index) => {
    setActiveIndex(index);
    const mainVideo = mainVideoRef.current[index];
    const videoContainer = videoContainerRef.current[index];
  
    if (mainVideo.paused) {
      if (videoContainer.requestFullscreen) {
        // Fullscreen API is supported (e.g., Android)
        videoContainer.requestFullscreen();
      } else if (videoContainer.webkitRequestFullscreen) {
        // Webkit Fullscreen API (older Safari)
        videoContainer.webkitRequestFullscreen();
      }
  
      mainVideo.play();
      setIsPlaying(true);
      setVideoStates((prevState) => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });
      setShowQuiz(false);
    } else {
      mainVideo.pause();
      setIsPlaying(false);
    }
  };

  const formatTime = (time) => {
    let seconds = Math.floor(time % 60),
      minutes = Math.floor(time / 60) % 60,
      hours = Math.floor(time / 3600);

    seconds = seconds < 10 ? `0${seconds}` : seconds;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    hours = hours < 10 ? `0${hours}` : hours;

    if (hours === 0) {
      return `${minutes}:${seconds}`;
    } else {
      return `${hours}:${minutes}:${seconds}`;
    }
  };


  //TIMERS
  const updateDuration = (index) => {
    const mainVideo = mainVideoRef.current[index];
    const videoDuration = videoDurationRef.current[index];
  
    const handleLoadedMetadata = () => {
      videoDuration.innerText = formatTime(mainVideo.duration);
    };
  
    mainVideo.addEventListener('loadedmetadata', handleLoadedMetadata);
  
    return () => {
      mainVideo.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  };
  useEffect(() => {
    const updateCurrentTime = (index) => {
      const mainVideo = mainVideoRef.current[index];
      const currentVidTime = currentVidTimeRef.current[index];
  
      const handleTimeUpdate = () => {
        currentVidTime.innerText = formatTime(mainVideo.currentTime);
      };
  
      mainVideo.addEventListener('timeupdate', handleTimeUpdate);
  
      return () => {
        mainVideo.removeEventListener('timeupdate', handleTimeUpdate);
      };
    };
  
    data.forEach((_, index) => {
      updateCurrentTime(index);
      updateDuration(index); // Use "loadedmetadata" event
    });
  }, [data]);



  //USER EARNED POINTS
  const handleVideoEnded = async (index, videoId) => {
    const videoIdlll = data[index].video_id;

    getRandomQuestion(data[index].questions)
    
    async function someAsyncFunction(userId, videoIdlll) {
      const earnPointsForVideoArray = await getUserEarnedPointsForVideo(userId);
      if (earnPointsForVideoArray.includes(videoIdlll)) {
        // console.log(videoIdlll, "bu video izlenmiş");
        // console.log("VIDEOLAR ", earnPointsForVideoArray);
        return true;
      } else {
        // console.log(videoIdlll, "bu video seyredilmemiş");
        // console.log("VIDEOLAR ", earnPointsForVideoArray);
        return false;
      }
    }

    setIsPlaying(false);
    if (user) {
      if (remainingVideoPoints[index] < 1) {
        console.log("REMAINING IF ÇALIŞTI");
        setExpiredVideoIndex(index);
        setShowQuiz(false); // Do not show quiz for videos with expired points
        setShowNoVideoPoint(true);
      } else if (await someAsyncFunction(user.uid, videoIdlll)) {
        // console.log(videoIdlll, "VIDEO IZLENMİŞ");
        // Display a message like "You earned points from this video before"
        // You can set a state variable to control the message display
        setShowMessage(true);
      } else {
        // console.log(videoIdlll, "VIDEO SEYREDİLMEMİŞ");
        setExpiredVideoIndex(null);
        setShowQuiz(true); // Show quiz for videos with remaining points
        setShowNoVideoPoint(false);
      }
    }
  };


  //FULL SCREEN CHANGE
  useEffect(() => {
    const handleFullscreenChange = () => {
      data.forEach((_, index) => {
        const mainVideo = mainVideoRef.current[index];
  
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        ) {
          // Handle fullscreen
          setVideoStates((prevState) => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
        } else {
          // Handle exiting fullscreen
          setVideoStates((prevState) => {
            const newState = [...prevState];
            newState[index] = false;
            return newState;
          });
          mainVideo.pause();
          setShowQuiz(false);
          setShowMessage(false);
        }
      });
    };
  
    const handleVideoEndedWrapper = (index) => {
      handleVideoEnded(index);
    };
  
    data.forEach((_, index) => {
      const video = mainVideoRef.current[index];
      video.addEventListener('ended', () => handleVideoEndedWrapper(index));
    });
  
    document.addEventListener('fullscreenchange', handleFullscreenChange);
  
    return () => {
      data.forEach((_, index) => {
        const video = mainVideoRef.current[index];
        if (video) {
          video.removeEventListener('ended', () => handleVideoEndedWrapper(index));
        }
      });
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [data, videoStates, handleVideoEnded]);


  //INITIAL REMANING VIDEO POINTS
  useEffect(() => {
    const initialRemainingVideoPoints = data.map((item) => item.remainingvideopoint);
    setRemainingVideoPoints(initialRemainingVideoPoints);
  }, [data]);

  const handleRemainingVideoPointUpdate = (updatedRemainingVideoPoint, index) => {
    setRemainingVideoPoints((prevState) => {
      const newState = [...prevState];
      newState[index] = updatedRemainingVideoPoint;
      return newState;
    });
  };


  
  // KULLANICI PUAN KISMI
  const [initialLoad, setInitialLoad] = useState(true);

  // useEffect to listen for changes in 'puan' and save the user's points
  useEffect(() => {
    if (initialLoad || videoIdforearned === null) {
      // Skip on initial load or when videoIdforearned is null
      if (initialLoad) {
        setInitialLoad(false);
      }
    } else if (puan !== null) {
      console.log("LINE 252 VIDEO ID : ", videoIdforearned)
      saveUserPoint(user, puan, videoIdforearned);
    }
  }, [puan, videoIdforearned]);

  //GET RANDOM QUESTION
  const getRandomQuestion = (questionsArray) => {
    console.log("questionsArray :", questionsArray);

    if (questionsArray && questionsArray.length > 0) {
      // Extracting the 'question' property from the random question object
      const randomQuestion = questionsArray[Math.floor(Math.random() * questionsArray.length)];
      const questionText = randomQuestion.question;
  
      setRandomQuestion(randomQuestion)
      console.log("222222 - randomQuestion :", randomQuestion);
  
      return <p className="px-5 text-xl md:text-2xl text-white font-extrabold">SORU : {questionText}</p>;
    } else {
      // Handle the case where questionsArray is empty
      return <p className="px-5 text-xl md:text-2xl text-white font-extrabold">No questions available</p>;
    }
  
  };

  //CLICK ANSWER
  const clickAnswer = (e, index) => {
    console.log("e :", e)

    // if (user && e === data[index].correct_answer_1) {
    if (user) {
      if (randomQuestion) {

        console.log("randomQuestion.answer :", randomQuestion.answer)
        const correctAnswer = randomQuestion.answer;
  
        if (e === correctAnswer) {
          const newPuan = puan + 5;
      dispatch({
        type: actionType.SET_PUAN,
        puan: newPuan,
      });

      const currentRemainingVideoPoint = remainingVideoPoints[index];
      const updatedRemainingVideoPoint = currentRemainingVideoPoint - 1;

      handleRemainingVideoPointUpdate(updatedRemainingVideoPoint, index);

      const companyId = data[index].company; // Replace with the actual company ID
      const videoId = data[index].video_id; // Replace with the actual video ID

      setVideoIdforearned(videoId)
      console.log("VIDEO ID : ", videoId);
      // console.log("data[index] : ", data[index]);
      // console.log("video_id : ", video_id);

   
      const updateData = {
        remainingvideopoint: updatedRemainingVideoPoint,
      };

      setFireStoretoRemainingVideoPoint(companyId, videoId, updateData);
      //console.log("videoId : ", videoId)
    }
  }}

//******************** */

    setIsAnswerClicked(true);
    // setIsFullscreen(false);
   
    setShowQuiz(false); // Hide the quiz after the answer is clicked
    setActiveIndex(null); // Reset the activeIndex after the quiz is hidden
    document.exitFullscreen();
  };

  // console.log("DATA IN VIDEO PLAYER:",data)
  return (
    <>
      {data && data.length > 0 ? (      
        data.map((item, index) => (     
          <motion.div whileHover={{ scale: 1.1 }} key={index} className="w-full">            
           <h1 className='text-base font-bold text-4xl flex justify-between'> 
              <span>{item.companyId}</span> 
              <span>{item?.video_title}</span>
           </h1>
            <div
              ref={(element) => (videoContainerRef.current[index] = element)}
              data-index={index}
              className={`vidcontainer ${showQuiz ? '' : 'show-controls'}`}
            >      
         
              {/*------- SHOW QUIZ ------- */}
              {
                  !user ?
                  (
                    isPlaying ? (<div className="overlay backdrop-brightness-50">
                    <div className="w-full flex flex-col space-y-32 xl:space-y-64 items-center gap-3 my-24 text-center flex-wrap justify-center">
                      <p className="px-5 text-xl md:text-2xl text-white font-extrabold">Please login to earn points.</p>
                      {/* Add any other elements or buttons as needed */}
                    </div>
                  </div>) : ''
                  )
                  :   
                  (showMessage ? (
                    <div className="overlay backdrop-brightness-50">
                    <div className="w-full flex flex-col space-y-32 xl:space-y-64 items-center gap-3 my-24 text-center flex-wrap justify-center">
                      <p className="px-5 text-xl md:text-2xl text-white font-extrabold">You earned points from this video before.</p>
                      {/* Add any other elements or buttons as needed */}
                    </div>
                  </div>         

                  ):
                  (
                    
                    user ?



                  (
                    videoStates[index] && showQuiz && !isPointsExpired ?  (
                      <div className="overlay backdrop-brightness-50">
                        <div className="w-full flex flex-col space-y-32 xl:space-y-64 items-center gap-3 my-24 text-center flex-wrap justify-center">
                          <div className="w-full flex flex-col gap-3 pt-4 space-y-24 items-center justify-center">
                            {/* <p className="px-5 text-xl md:text-2xl text-white font-extrabold">SORU : {item.question_1}</p> */}

                    {/* Display a random question */}
                    {/* <p className="random-question">
                    {console.log("videoStates :", videoStates)}
                    {console.log("index :", index)}
                    {console.log("{item.question_1} :", item.questions)}
                
                      {getRandomQuestion(item.questions)}  </p> */}

                      {console.log("333333 - randomQuestion.question :", randomQuestion.question)}
                    {/* {getRandomQuestion(item.questions)}                    */}
                          {/* {randomQuestion.question} */}

                          <p className="px-5 text-xl md:text-2xl text-white font-extrabold">SORU : {randomQuestion.question}</p>

                          </div>
                          <div className="w-full flex flex-row gap-3 space-x-12 xl:space-x-36 items-center justify-center">
                            <motion.button
                              whileHover={{ scale: 1.5 }}
                              onClick={(e) => clickAnswer(e.target.textContent, index)}
                              className="text-4xl text-yellow-50 font-semibold hover:drop-shadow-lg"
                            >
                            Evet
                            </motion.button>
                            <motion.button
                              whileHover={{ scale: 1.5 }}
                              onClick={(e) => clickAnswer(e.target.textContent, index)}
                              className="text-4xl text-yellow-50 font-semibold hover:drop-shadow-lg"
                            >
                              Hayır
                            </motion.button>
                          </div>
                        </div>
                      </div>
                    )              
                    :                
                    videoStates[index] && showNoVideoPoint && expiredVideoIndex === index && (
                      <div className="overlay backdrop-brightness-50">
                        <div className="w-full flex flex-col space-y-32 xl:space-y-64 items-center gap-3 my-24 text-center flex-wrap justify-center">
                          <p className="px-5 text-xl md:text-2xl text-white font-extrabold">This video's points are expired.</p>
                          {/* Add any other elements or buttons as needed */}
                        </div>
                      </div>
                    )
                  )
                  
                  
                  
                  
                  :null))              
              }            

            {/* ------ VIDEO CONTROLS ------ */}
              <div className="wrapper">
                <ul className="video-controls">
                  <li className="options left">
                    <div className="video-timer">
                      <p ref={(el) => (currentVidTimeRef.current[index] = el)} className="current-time">
                        00:00
                      </p>
                      <p className="separator"> / </p>
                      <p ref={(el) => (videoDurationRef.current[index] = el)} className="video-duration">
                        00:00
                      </p>
                    </div>
                  </li>
                  <li className="options center">
                    <button onClick={(e) => playPauseVideo(e, index)} className="play-pause">
                      <i className={`fas ${videoStates[index] && isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                    </button>
                  </li>
                  <li className="options right">
                    <p className="vidtitle">
                      {item?.video_title} 
                      <hr />
                      {remainingVideoPoints[index]} of {item.initialvideopoint}                    
                    </p>
                    
                   
                  </li>
                </ul>
              </div>

              

              <div>                
                <video
                  ref={(element) => (mainVideoRef.current[index] = element)}
                  data-index={index}
                  src={item?.video_url + "#t=0.001" }
                  onClick={(e) => playPauseVideo(e, index)}
                  onEnded={() => handleVideoEnded(index)}
                             
                ></video>


                
              </div>
            </div>

          </motion.div>
          
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" />
          <p className="text-xl text-headingColor font-semibold my-4 ">No videos available</p>
        </div>
      )}
    </>
  );
};

export default VideoComponent;
