import { initializeApp, getApp, getApps } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCTcnGqq29AAIt0oX53QR5XVP3QbXJZPRw",
  authDomain: "video-blog-ccce0.firebaseapp.com",
  databaseURL: "https://video-blog-ccce0-default-rtdb.firebaseio.com",
  projectId: "video-blog-ccce0",
  storageBucket: "video-blog-ccce0.appspot.com",
  messagingSenderId: "916891440204",
  appId: "1:916891440204:web:2048cde27e7ddd096210e9"
};

// Initialize Firebase
const app = getApps.Length > 0 ? getApp() : initializeApp(firebaseConfig);

const firestore = getFirestore(app)
const storage = getStorage(app)

export {app, firestore, storage}