import React, { useState,  } from 'react';
import { MdLogout } from 'react-icons/md';
import { motion } from 'framer-motion';

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import {  doc, setDoc } from "firebase/firestore";
import { app } from '../firebase-config';
import { firestore } from "../firebase-config";
import { fetchUserFromFirebase } from '../utils/FirebaseFunctions';

import Logo from './img/tr_flag2.gif';
import Avatar from './img/avatar.png';
import { Link, useNavigate  } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import { actionType } from '../context/reducer';

const Header = () => {
    const firebaseAuth = getAuth(app);
    const provider = new GoogleAuthProvider();
    const [{ user, cartShow, cartItems, puan }, dispatch] = useStateValue();
    const [isMenu, setIsMenu] = useState(false);
    const navigate = useNavigate();
 

    const login = async () => {
        if (!user) {
            try {
                const result = await signInWithPopup(firebaseAuth, provider);
                const user = result.user;
                const providerData = user.providerData[0];

                dispatch({
                    type: actionType.SET_USER,
                    user: providerData,
                });

                // Fetch user point data and set it in the state
                const userPoint = await fetchUserFromFirebase(firestore, providerData.uid);
                // console.log("USER POINT :" ,userPoint)
                if (userPoint.userPoint === undefined) {
                    // Set userPoint to null if it's undefined (i.e., not found in Firebase)
                    // setUserPoint(null); // Replace setUserPoint with your state update logic
                    dispatch({
                        type: actionType.SET_PUAN,
                        puan: null, // Make sure to update this based on your Firebase data structure
                    });

                    
                  } else {
                    // Set userPoint to the value retrieved from Firebase
                    // setUserPoint(userPoint); // Replace setUserPoint with your state update logic
                    dispatch({
                        type: actionType.SET_PUAN,
                        puan: userPoint.userPoint, // Make sure to update this based on your Firebase data structure
                    });
                  }

                localStorage.setItem("user", JSON.stringify(providerData));
                localStorage.setItem("accessToken", JSON.stringify(result.refreshToken));

                await setDoc(
                    doc(firestore, "users", providerData.uid),
                    providerData,
                    { merge: true }
                );
            } catch (error) {
                console.error("Error during login: ", error);
            }
        } else {
            setIsMenu(!isMenu);
        }
    };

    const logout = () => {
        setIsMenu(false);
        localStorage.clear();
        dispatch({
            type: actionType.SET_USER,
            user: null,
        });
        dispatch({
            type: actionType.SET_PUAN,
            puan: null, 
        });

        
        navigate('/'); 
    };

    const showCart = () => {
        dispatch({
            type: actionType.SET_CART_SHOW,
            cartShow: !cartShow,
        });
    };

    window.addEventListener('beforeunload', (event) => {
        // Prevent the default confirmation prompt
        event.preventDefault();
      
       
      
        // Display a custom message (optional)
        event.returnValue = 'You are about to log out. Are you sure?';

         // Call the signOutOnWindowClose function to log the user out of Firebase
         logout();
         
      });

  return (
    <header className='fixed z-50 w-screen p-3 px-4 md:p-6 md:px-16 bg-primary' >
        {/*Dektop and Tablet */}
        <div className='hidden md:flex w-full h-full items-center justify-between'>
            <Link to={'/'} className='flex items-center gap-2'  >
                <img src={Logo} alt="logo" className='w-2/6 object-recover' />
                {/* <p className='text-headingColor text-xl font-bold'>City</p> */}
            </Link>  

            <div className='flex items-center gap-8'>
                <motion.ul 
                animate={{opacity : 1, x : 0 }}
                initial={{opacity : 0, x : 200 }}
                exit={{opacity : 0, x : 200 }}
                className='flex items-center gap-8'>
                    
                    {/* <Link to={'/'}> <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer '>Home</li></Link>
                    <Link to={'Anasayfa'}>
                    <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer '>AnaSayfa</li>
                    </Link>
                    <Link to={'Temp'}>
                    <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer '>Temp</li>
                    </Link> */}
                    
                    {/* <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer '>About Us</li>
                    <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer '>Service</li> */}
                    {/* <Link to={'ListUserContents'}><li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer '>User Content</li></Link> */}

                </motion.ul>

                <div className="relative flex items-center justify-center" onClick={showCart}>
                    { user ?  <p>Puan : {puan}</p> : ''}
                    {/* <p>Puan : {puan}</p> */}
                    {/* <MdShoppingBasket className='text-textColor text-2xl cursor-pointer' /> */}
                    {/* {cartItems && cartItems.length > 0 && (
                        <div className='absolute -top-2 -right-2 w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center' >
                            <p className='text-xs text-white font-semibold'>{cartItems.length}</p>
                        </div>
                    )} */}
                </div>


                
                {/* AVATAR */}

                <div className="relative group">
                    <motion.img whileTap={{scale: 0.6}} src={user ? user.photoURL : Avatar} className='w-100 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl rounded-full cursor-pointer' alt="userprofile" onClick={login}/>  
                
                    {/* DropDown Menu */}
                    {
                        isMenu && (
                            <motion.div 
                            initial={{opacity: 0, scale: 0.6 }}
                            animate={{opacity: 1, scale: 1 }}
                            exit={{opacity: 0, scale: 0.6 }} 
                            className="w-40 bg-gray-50 shadow-xl rounded-lg flex flex-col absolute top-12 right-0">
                        
                            {/* {
                                user && user.email === "csspdnet@gmail.com" && (
                                    <Link to='/createItem'>
                                        <p className='px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base'
                                        onClick={()=>setIsMenu(false)}                                        
                                        >
                                            New Item <MdAdd /> </p>   
                                    </Link>
    
                                )
                            } */}
    
                            <p className='px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base'
                            onClick={logout}
                            >Logout <MdLogout /> </p>
                        </motion.div>
                        ) 
                    }

                </div>




            </div> 
        </div>

        {/*Mobile---------------------------------------- */}
        <div className='flex items-center justify-between md:hidden w-full h-full'>
            {/* <div className="relative flex items-center justify-center" onClick={showCart} >
                <MdShoppingBasket className='text-textColor text-2xl cursor-pointer' />
                {cartItems && cartItems.length > 0 && (
                        <div className='absolute -top-2 -right-2 w-5 h-5 rounded-full bg-cartNumBg flex items-center justify-center' >
                            <p className='text-xs text-white font-semibold'>{cartItems.length}</p>
                        </div>
                    )}
            </div> */}

            <Link to={'/'} className='flex items-center gap-2'  >
                <img src={Logo} alt="logo" className='w-2/6 object-recover' />
                {user ? (<p className='text-headingColor text-xl'>Puan : {puan}</p>) : null }
            </Link> 

            <div className="relative">
                    <motion.img whileTap={{scale: 0.6}} src={user ? user.photoURL : Avatar} className='w-100 min-w-[40px] h-10 min-h-[40px] drop-shadow-xl rounded-full cursor-pointer' alt="userprofile" onClick={login}/>    
                
                    {/* DropDown Menu */}
                    {
                        isMenu && (
                            <motion.div 
                            initial={{opacity: 0, scale: 0.6 }}
                            animate={{opacity: 1, scale: 1 }}
                            exit={{opacity: 0, scale: 0.6 }} 
                            className="w-40 bg-gray-50 shadow-xl rounded-lg flex flex-col absolute top-12 right-0">
                        
                            {/* {
                                user && user.email === "csspdnet@gmail.com" && (
                                    <Link to='/createItem'>
                                        <p className='px-4 py-2 flex items-center gap-3 cursor-pointer hover:bg-slate-100 transition-all duration-100 ease-in-out text-textColor text-base'
                                        onClick={()=>setIsMenu(false)}
                                        >
                                            New Item <MdAdd /> </p>   
                                    </Link>
    
                                )
                            } */}

{/* 
                    <ul 
                        className='flex flex-col'>
                            <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer hover:bg-slate-100  px-4 py-2'
                            onClick={()=>setIsMenu(false)}
                            >Home</li>
                            <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer hover:bg-slate-100 px-4 py-2'
                            onClick={()=>setIsMenu(false)}
                            >Menu</li>
                            <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer hover:bg-slate-100 px-4 py-2'
                            onClick={()=>setIsMenu(false)}
                            >About Us</li>
                            <li className='textbase text-textColor hover:text-headingColor duration-100 transition-all ease-in-out cursor-pointer hover:bg-slate-100 px-4 py-2'
                            onClick={()=>setIsMenu(false)}
                            >Service</li>
                    </ul> */}




    
                            <p className='m-2 p-2 rounded-md shadow-md flex items-center justify-center bg-gray-200 gap-3 cursor-pointer hover:bg-slate-300 transition-all duration-100 ease-in-out text-textColor text-base'
                            onClick={logout}
                            >Logout <MdLogout /> </p>
                        </motion.div>
                        ) 
                    }

                </div>



        </div>

    </header>
  );
}

export default Header;
