import React, { useEffect, useRef, useState } from 'react';
import MenuContainer from './MenuContainer';

const Anasayfa = () => {




  return (
    <div className='w-full h-auto flex flex-col items-center justify-center '>

    <MenuContainer />

    </div>
  );
}

export default Anasayfa;