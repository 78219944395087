import { collection, collectionGroup, exists, doc, getDoc, getDocs, orderBy, query, setDoc, where, onSnapshot,updateDoc, arrayUnion    } from "firebase/firestore"
import { firestore } from "../firebase-config"

//--------------------SAVE-----------------------------------------------------------//


// Saving new Item --- ORIGINAL
export const saveItem = async (data) => {
    const timestamp = Date.now().toString().trim()
    await setDoc(doc(firestore, 'companylist', `${data.company}`, 'companyvideos', timestamp), data, {merge : true})
}

// SAVING USER POINT AND EARNED POINT VIDEO
export const saveUserPoint = async (user, puan, videoId) => {
// console.log("SAVE USER ÇALIŞTI : " )

    if (puan === null) {
        console.log("puan is null. Skipping update in Firebase.");
        return; // Don't update Firebase if puan is null
      }else{

        //     // console.log("USERRRRR : ", user[0].uid)
        //     console.log("PUANNN : ", puan)
        //     console.log("USERRRR : ", user.uid)        
            // await setDoc(doc(firestore, 'users', `${user.uid}`), { userPoint: puan }, {merge : true})

             //Kullanıcı puanı kayıt edilirken , puan aldığı video IDsi de kayıt edilecek
            const userDocRef = doc(firestore, 'users', user.uid); // Reference to the user's document
            await updateDoc(userDocRef, { 
                userPoint: puan,
                earnPointsForVideo: arrayUnion(videoId)
            } );
            // console.log('User Puan ve Video Bilgisi kaıt edildi.');
      }
    

 }



//KALAN PUAN BİLGİSİNİN VİDEO DOKUMANI ALTINDA FIELDA KAYIT EDİLMESİ
export const setFireStoretoRemainingVideoPoint = async (companyId,videoId,remainingvideopoint) =>{
    const videoDocRef = doc(firestore, 'companylist', companyId, 'companyvideos', videoId);
    // console.log("VIDEO ID : ", videoId)
    try {
        const videoDocSnapshot = await getDoc(videoDocRef);
        // console.log("videoDocSnapshot : ", videoDocSnapshot)        
        if (videoDocSnapshot.exists()) {
          await setDoc(videoDocRef, remainingvideopoint, { merge: true });
          console.log('Document successfully updated.');
        } else {
          console.log('Document with videoId does not exist.');
        }
      } catch (error) {
        console.error('Error updating document: ', error);
      }
}  


//********************************************************************************* */
//----------------FETCH-------------------------------------------------------
//******************************************************************************* */


//FETCH COMPANIES TITLE
export const getCompaniesTitles = async (FirestoreDb) => {
  const companyRef = collection(FirestoreDb, 'companylist');  
  const q = await query((companyRef))
  const companyTitles = await getDocs(q);

  return companyTitles.docs.map((doc)=>doc.data());
}

//Fetch the user information user userid
export const fetchUserFromFirebase = async(FirestoreDb, userId) => {
    const userRef = doc(FirestoreDb, 'users', userId);
    const userSnap = await getDoc(userRef);
// console.log("USER SNAP .DATA : ", userSnap.data())

    if(userSnap.exists()){
        return userSnap.data()
    }else{
        return "No Such Document"
    }
};


//Fetch Company Names to create Company content
export const companyNames = async (FirestoreDb) => {
    const companiesRef = FirestoreDb.collection("companies");

    companiesRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
      });
    });
}

//Fetch the specific video
export const getSpecificVideo = async(FirestoreDb, videoId) => {
    const videoRef = doc(FirestoreDb, 'videos', videoId);
    const videoSnap = await getDoc(videoRef);
    if(videoSnap.exists()){
        return videoSnap.data()
    }else{
        return "No Such Document"
    }
};

//Fetch the user based videos
export const getUserBasedVideos = async(FirestoreDb, userId) => {
    // console.log("FIRESTORE DB: " ,FirestoreDb);
    // console.log("FETCH USER ID: " ,userId);
    const collectionRef = collection(FirestoreDb, "videos");
    const userVideos = query(collectionRef,where('userId', '==', userId))   
    const videoSnap = await getDocs(userVideos);
    // console.log("LENGTH : ",videoSnap.docs.length );

    if(videoSnap.docs.length > 0) {
        var userBasedVideos = [];
        videoSnap.forEach((doc)=> {
            var data = doc.data();
            data.ID = doc.id;
            userBasedVideos.push({...data});
        })

        return userBasedVideos;

    }
};

// FETCH USER EARNED VIDEOS
export const getUserEarnedPointsForVideo = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnapshot = await getDoc(userDocRef);
  
    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const earnPointsForVideo = userData.earnPointsForVideo || [];

      return earnPointsForVideo;
    } else {
      return false; // User document doesn't exist or has no "earnPointsForVideo" array
    }
  };



//FETCH FILTERED COMPANIES AND VIDEOS OF THE FILTERED COMPANIES--------------------

// export const getAllCompaniesFeeds = async (FirestoreDb, companyId) => {
//     const companyRef = doc(FirestoreDb, 'companylist', `${companyId}`)
//     const collectionRef = (collection(companyRef, 'companyvideos'))
//     const q = await query(collectionRef)
//     const companyvideos = await getDocs(q);
//     // console.log("COMPANY ID : ", companyId)    
//     // console.log("COMPANY VIDEOS : " ,companyvideos)

//     return companyvideos.docs.map((doc)=>doc.data());
// }


//---------------------------------------------------------

// export const getAllCompaniesFeeds = async (FirestoreDb, filter) => {
//   try {
//     const companiesRef = collection(FirestoreDb, 'companylist');
//     let companiesQuery = query(companiesRef);
    
//     if (filter.title) {
//       companiesQuery = query(companiesRef, where('title', '==', filter.title));
//     }
    
//     if (filter.location) {
//       companiesQuery = query(companiesRef, where('location', '==', filter.location));
//     }
    
//     if (filter.industry) {
//       companiesQuery = query(companiesRef, where('industry', '==', filter.industry));
//     }
    
//     const snapshot = await getDocs(companiesQuery);
//     const data = snapshot.docs.map((doc) => doc.data());

//     return data;
//   } catch (error) {
//     console.error("Error in getAllCompaniesFeeds:", error);
//     throw error;
//   }
// };

//----------------------------------------------------------------------------------------------

//------------------------------------------------------------------------

export const getAllCompaniesFeeds = async (FirestoreDb, filter) => {
  try {

    // console.log("FILTER IN FIREBASE FUNCTION :", filter)

    const companiesRef = collection(FirestoreDb, 'companylist');
    const companiesQuery = buildCompaniesQuery(companiesRef, filter);
    const companiesSnapshot = await getDocs(companiesQuery);

    // console.log("companiesQuery :", companiesQuery)
    // console.log("companiesSnapshot.docs :", companiesSnapshot.docs)



    const videosData = [];

    for (const companyDoc of companiesSnapshot.docs) {
      const videosRef = collection(companyDoc.ref, 'companyvideos');
      const videosSnapshot = await getDocs(videosRef);

      // console.log("videosSnapshot :", videosSnapshot)

      videosSnapshot.forEach((videoDoc) => {
        videosData.push({
          companyId: companyDoc.id,
          videoId: videoDoc.id,
          ...videoDoc.data(),
        });
      });
    }

    return videosData;
  } catch (error) {
    console.error('Error getting videos:', error);
    return [];
  }
};

const buildCompaniesQuery = (companiesRef, filter) => {
  let queryBuilder = companiesRef;

  if (filter.title) {
    queryBuilder = query(queryBuilder, where('title', '==', filter.title));
  }
  if (filter.location) {
    queryBuilder = query(queryBuilder, where('location', '==', filter.location));
  }
  if (filter.industry) {
    queryBuilder = query(queryBuilder, where('industry', '==', filter.industry));
  }

  return queryBuilder;
};

//--------------------------------------------------------

























// export const hasUserEarnedPointsForVideo = async ( userId, videoId) => { 
//     console.log("FIRESTORE DB - 2 : ", firestore);
//     console.log("USER ID FIREBASE FUNCTION : ", userId)
//     console.log("VIDEO ID FIREBASE FUNCTION : ", videoId)
//     const userRef = firestore.collection("users").doc(userId);
        
//     // Check if the user has earned points for the video.
//     const querySnapshot = await userRef.where('earnPointsForVideo', 'array-contains-any', [videoId]).get();
    
//     // Get the documents that match the query.
//     const documents = querySnapshot.docs;
    
//     // Return true if the user has earned points for the video, false otherwise.
//     return documents.length > 0;
//     };
  
  // Usage
//   const videoIdToCheck = /* the video ID you want to check */;
  
//   hasUserEarnedPointsForVideo(videoIdToCheck)
//     .then((hasEarnedPoints) => {
//       console.log("Has user earned points for the video?", hasEarnedPoints);
//     });





//------------------TOOL BOX------------------------------------------//
        // export const saveUserPoint = async (user, puan) => {

        //     // console.log("USERRRRR : ", user[0].uid)
        //     console.log("PUANNN : ", puan)
        //     console.log("USERRRR : ", user.uid)

        
        //     await setDoc(doc(firestore, 'users', `${user.uid}`), { userPoint: puan }, {merge : true})

        //      // await setDoc(doc(firestore, 'companylist', `${data.company}`, 'companyvideos', `${Date.now()}`), data, {merge : true})
        // }


        //PUANI INITIAL STATE'E ÇAĞIRMAK İÇİN ÇALIŞMA
        // console.log("FETCH USER : ", userInfo)
        // const puan = () => {
        //     if(userInfo) {
        //         fetchUserFromFirebase(firestore, userInfo.uid)
        //            .then((result)=>{
        //                if(result){
        //                console.log("fetchUserFromFirebase : ", result) 
        //                // if(result===providerData[0].uid){
        //                return result.uid
        //            }
        //                // }
        
        //        })
        //    }
        // }

        //SET REMAINING VIDEO POINT
        // export const setFireStoretoRemainingVideoPoint = async (companyId,videoId,remainingvideopoint) => {

        // console.log("companyId :", companyId)
        // console.log("videoId :", videoId)
        // console.log("remainingvideopoint :", remainingvideopoint)

        //     await setDoc(doc(firestore, 'companylist', companyId, 'companyvideos', videoId ), remainingvideopoint, {merge : true})
        // }