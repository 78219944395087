import React, { useEffect, useState } from 'react';
import { IoFastFood } from 'react-icons/io5';
import { motion } from 'framer-motion';
import { useStateValue } from '../context/StateProvider';
import RowContainer from './RowContainer';
import { getAllCompaniesFeeds, getCompaniesTitles } from '../utils/FirebaseFunctions';
import { firestore } from '../firebase-config';
import VideoComponent from './videoplayer/Videoplayer';
import FilterContainer from './FilterContainer';

const MenuContainer = () => {
 
  const firestoreDb = firestore;
  const [{ foodItems }, dispatch] = useStateValue();

  const [filter, setFilter] = useState({
    title: '',
    location: '',
    industry: '',
  });

  const [companiesTitles, setCompaniesTitles] = useState([]);
  const [companiesfeeds, setCompaniesFeeds] = useState([]);

  useEffect(() => {
    // Fetch the available companies' titles
    getCompaniesTitles(firestoreDb).then((data) => {
      setCompaniesTitles(data);
    });
  }, []);

  const handleFilterChange = (selectedOptions) => {
    setFilter(selectedOptions);
  };

  useEffect(() => {
    // Clear the existing data
    setCompaniesFeeds([]);
    // console.log('Filter object:', filter);

    // Fetch videos based on the selected filter
    getAllCompaniesFeeds(firestoreDb, filter).then((data) => {
      console.log('Data from API:', data);
      setCompaniesFeeds(data);
    });
  }, [filter]);


  //TEMP - SİLDİM----------------------------------------------
  const getCompaniesForFilter = (filter) => {
    // Create an array of filtering functions based on selected criteria
    const filters = [];
    if (filter.title) {
      filters.push((video) => video.title === filter.title);
    }
    if (filter.location) {
      filters.push((video) => video.location === filter.location);
    }
    if (filter.industry) {
      filters.push((video) => video.industry === filter.industry);
    }

    // Filter videos based on the selected criteria
    return companiesfeeds.filter((video) =>
      filters.every((filterFunction) => filterFunction(video))
    );
  };
//-----------------------------------------------------------------


  // Get Compaines based on the selected filter
  const filteredCompanies = getCompaniesForFilter(filter);
  // console.log("filteredCompanies :" ,filteredCompanies)


  // Array to store video data
  // const videosData = [];









  return (
    <section className="w-full my-9" id="menu">
      <div className="w-full flex flex-col items-center justify-center">
        <p className="text-2xl font-semibold capitalize text-headingColor relative before:absolute before:rounded-lg before:content before:w-16 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-l from-orange-400 to-orange-600 transition-all ease-in-out duration-100 mr-auto">
          Let's Watch
        </p>
        <br/>

        {/* FILTER SECTION */}
        <FilterContainer data={companiesTitles} onFilterChange={handleFilterChange} />
{/* {console.log("onFİlterChange :", handleFilterChange() )} */}
        <div className="w-full flex items-center justify-start lg:justify-center gap-8 py-6 overflow-x-scroll scrollbar-none">
          {companiesTitles &&
            companiesTitles.map((category, index) => (
              <motion.div
                whileTap={{ scale: 0.75 }}
                key={index}
                className={`group ${
                  filter.title === category.title ? 'bg-cartNumBg' : 'bg-card'
                } w-24 min-w-[94px] h-28 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-3 items-center justify-center hover:bg-cartNumBg `}
                onClick={() => handleFilterChange({ title: category.title })}
              >
                <div
                  className={`w-10 h-10 rounded-full shadow-lg ${
                    filter.title === category.title ? 'bg-white' : 'bg-cartNumBg'
                  } group-hover:bg-white flex items-center justify-center`}
                >
                  <IoFastFood
                    className={`${
                      filter.title === category.title ? 'text-textColor' : 'text-white'
                    } group-hover:text-textColor text-lg`}
                  />
                </div>
                <p
                  className={`text-sm ${
                    filter.title === category.title ? 'text-white' : 'text-textColor'
                  } group-hover:text-white`}
                >
                  {category.title}
                </p>
              </motion.div>
            ))}
        </div>

        {/* LIST SECTION */}
        {/* Render your RowContainer or VideoComponent here with the filtered data */}
        {/* Example with RowContainer:
        <div className="w-full ">
          <RowContainer flag={false} data={companiesfeeds} companyId={filter.title} />
        </div>
        */}

        <div className="grid lg:grid-cols-4 mdr:grid-cols-3  sm:grid-cols-2 gap-3 grid-cols-1">
          {/* <VideoComponent flag={false} data={filteredCompanies}/> */}

          <VideoComponent flag={false} data={companiesfeeds}/>
          
        </div>
      </div>
    </section>
  );
};

export default MenuContainer;
