import React, { useState } from 'react';

const FilterContainer = ({ data, onFilterChange }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    title: '',
    location: '',
    industry: '',
  });
  const [infoMessage, setInfoMessage] = useState('');

  // Create unique arrays for each dropdown option
  const uniqueTitleOptionsArray = [...new Set(data.map((item) => item.title))];
  const uniqueLocationOptionsArray = [...new Set(data.map((item) => item.location))];
  const uniqueIndustryOptionsArray = [...new Set(data.map((item) => item.industry))];

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedItems = Object.values(selectedOptions).filter((item) => item !== '');

    if (selectedItems.length > 0) {
      setInfoMessage(`You have selected: ${selectedItems.join(', ')}`);
      // Call the onFilterChange function to update the filter in MenuContainer
      onFilterChange(selectedOptions); // Pass the selected options to MenuContainer
    } else {
      setInfoMessage('Please select at least one option to get info.');
    }
  };

  return (
    <div>
      <form className='sm:flex flex-row items-start' onSubmit={handleSubmit}>
        <select
          className='bg-orange-100 text-base text-blue-600 mb-2 mr-2'
          name="title"
          id="title"
          value={selectedOptions.title}
          onChange={handleOptionChange}
         
        >
          <option value="">Companies</option>
          {uniqueTitleOptionsArray.map((title, index) => (
            <option key={index} value={title}>
              {title}
            </option>
          ))}
        </select>

        <select
          className='bg-orange-100 text-base text-blue-600 mb-2 mr-2'
          name="location"
          id="location"
          value={selectedOptions.location}
          onChange={handleOptionChange}
         
        >
          <option value="">Location</option>
          {uniqueLocationOptionsArray.map((location, index) => (
            <option key={index} value={location}>
              {location}
            </option>
          ))}
        </select>

        <select
          className='bg-orange-100 text-base text-blue-600 mb-2 mr-2'
          name="industry"
          id="industry"
          value={selectedOptions.industry}
          onChange={handleOptionChange}
          style={{ width: '100px' }} // Set the desired width here
        >
          <option value="">Industry</option>
          {uniqueIndustryOptionsArray.map((industry, index) => (
            <option key={index} value={industry}>
              {industry}
            </option>
          ))}
        </select>

        {/* <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-1 border border-blue-500 hover:border-transparent rounded-full mb-2" type="submit">Submit</button> */}

        {/* <button type="submit" class="py-1 px-1 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
         Submit
        </button> */}

<button type="submit" class="py-1 px-1 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-teal-500 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-white dark:border-gray-700 dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
Submit
</button>
      </form>

      {infoMessage && <p className='text-base text-orange-500 font-semibold'>{infoMessage}</p>}
    </div>
  );

  
  
  
  
  
};

export default FilterContainer;
