import { getAllFoodItems } from "../utils/FirebaseFunctions";

export const actionType = {
    SET_USER : 'SET_USER',
    SET_FOOD_ITEMS: "SET_FOOD_ITEMS",
    SET_CART_SHOW : 'SET_CART_SHOW',
    SET_CARTITEMS : 'SET_CARTITEMS',
    SET_PUAN : 'SET_PUAN',
    SET_REMAINING_VIDEO_POINT : "REMAINING_VIDEO_POINT"
}

const reducer = (state, action) => {
    // console.log(action);

    switch(action.type){
        case actionType.SET_USER:
            return {
                ...state,
                user : action.user,
            };
        case actionType.SET_FOOD_ITEMS:
            return {
                ...state,
                foodItems : action.foodItems,
            };

        case actionType.SET_CART_SHOW:
            return {
                ...state,
                cartShow : action.cartShow,
            };
        case actionType.SET_CARTITEMS:
            return {
                ...state,
                cartItems : action.cartItems,

            };
        case actionType.SET_PUAN:
            return {
                ...state,
                puan : action.puan,

            };
        case actionType.SET_REMAINING_VIDEO_POINT:
            return {
                ...state,
                remainingvideopoint : action.remainingvideopoint,

            };
        default : 
        return state;
    }

}

export default reducer;